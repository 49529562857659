import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    Table,
    FormGroup,
    ButtonGroup,
    Button,
    Input,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getOwners} from '../Owners/OwnerFunctions.js';
import {getProducts} from '../Products/ProductFunctions.js';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import PaymentStatus from '../Orders/PaymentStatus';
import DeliveryStatus from '../Orders/DeliveryStatus';
import OrderReturn from "../Orders/OrderReturn";

moment.tz.setDefault("America/Toronto");

var money = require("money-math");


class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.updateDate = this.updateDate.bind(this);
        this.updateEstDate = this.updateEstDate.bind(this);
        this.state = {
            mo_outstanding: 0,
            order_id: null,
            credit_order_id: 0,
            credit_memo: 0,
            subtotal: 0,
            tax: 0,
            deposit: 0,
            total: 0,
            credit_applied: 0,
            payable: 0,
            credit: 0,
            items: [],
            payments: [],
            downloading: false,
            loading: true,
            inventory_source_id: 0,
            inventory_sources: [],
            notes: undefined,
            delivery_instructions: "",
            purchase_order: null,
            select_payment_status: null,
            select_inventory_source: null,
            select_delivery_status: null,
            select_delivery_date_status: null,
            save_payment_status_button: "Save Changes",
            delivery_statuses: {0: "Processing", 1: "Scheduled", 2: "Out for Delivery", 3: "Delivered", 9: "Held - Pending Approval"},
            est_delivery_date: null,
            delivery_date: null,
            void_date: null,
            record_payment_status: 0,
            manual_payment_amount: 0,
            cheque_number: "",
            payment_methods: {0: "Account Credit", 1: "Credit Card", 3: "EFT", 4: "Cheque", 5: "Cash",6:"E-Transfer"},
        };
        this.editPaymentStatus = this.editPaymentStatus.bind(this);
        this.loadOrder = this.loadOrder.bind(this)
    }

    componentDidMount() {
        document.title = "Order # " + this.props.match.params.order_id + " | BevOps";
        this.setState({order_id: this.props.match.params.order_id});
        var self = this;

        getOwners(function (owners) {
            getProducts(function (products) {
                getInventorySources('can_deliver', function (inventory_sources) {
                    inventory_sources['0'] = {
                        'inventory_source_id': 0,
                        'name': '- Not Selected -'
                    };
                    self.setState({
                        inventory_sources: inventory_sources,
                        owners: owners,
                        products: products
                    }, () => {
                        self.loadOrder(self.props.match.params.order_id);
                    });
                });
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (typeof this.props.match.params != "undefined" && prevProps.match.params.order_id !== this.props.match.params.order_id) {
            console.log('props updated')
            this.loadOrder(this.props.match.params.order_id);
        }
    }

    loadOrder = (order_id) => {
        if (typeof order_id != "undefined") {
            var self = this;
            axios.get(API_ROOT + '/order/' + order_id)
                .then(function (response) {
                    var data = response.data;
                    if (data.est_delivery_date != null) {
                        data.est_delivery_date = moment(data.est_delivery_date, 'MMM. D, YYYY');
                    }
                    if (data.delivery_date != null) {
                        data.delivery_date = moment(data.delivery_date);
                    }
                    let zero = money.floatToAmount(0);
                    var total_total = zero;
                    var total_subtotal = zero;
                    var total_tax = zero;
                    var total_deposit = zero;
                    var cart = {};
                    data['total_paid'] = response.data['paid'];
                    response.data.items.forEach(function (item, key) {
                        let product = item;
                        let cart_owner = cart[product.product_owner_id];
                        if (typeof cart_owner == "undefined") {
                            cart[product.product_owner_id] = {
                                "items": [],
                                "subtotal": zero,
                                "tax": zero,
                                "deposit": zero,
                                "total": zero,
                                "quantity": 0
                            };
                            cart_owner = cart[product.product_owner_id]; //set because was undefined
                        }
                        let subtotal = money.floatToAmount(item.subtotal);
                        let deposit = money.floatToAmount(item.deposit);
                        let tax = money.floatToAmount(item.tax);
                        let total = money.add(tax, money.add(deposit, subtotal));
                        cart[product.product_owner_id].items.push({
                            product_id: item.product_id,
                            quantity: money.floatToAmount(item.quantity),
                            subtotal: subtotal,
                            tax: tax,
                            deposit: deposit,
                            total: total
                        });
                        cart[product.product_owner_id].quantity = (item.quantity + cart_owner.quantity);
                        cart[product.product_owner_id].subtotal = money.add(subtotal, cart_owner.subtotal);
                        cart[product.product_owner_id].tax = money.add(tax, cart_owner.tax);
                        cart[product.product_owner_id].deposit = money.add(deposit, cart_owner.deposit);
                        cart[product.product_owner_id].total = money.add(total, cart_owner.total);

                        total_subtotal = money.add(subtotal, total_subtotal);
                        total_tax = money.add(tax, total_tax);
                        total_deposit = money.add(deposit, total_deposit);
                        total_total = money.add(total, total_total);
                    });
                    data['payments'] = response.data.payments;
                    data['cart'] = cart;
                    data['loading'] = false;
                    data['payable'] = parseFloat(parseFloat(total_total) - parseFloat(data['total_paid']));
                    data['manual_payment_amount'] = data['payable'];

                    data['inventory_source_name'] = "- Not Selected -";
                    var inventory_sources = self.state.inventory_sources;
                    if (data['credit_order_id'] > 0) {
                        inventory_sources['0'] = {
                            'inventory_source_id': 0,
                            'name': 'Product Not Returned / Written Off'
                        };
                        data['inventory_sources'] = inventory_sources;
                    }
                    if (data['account_type'] == 2) {
                        inventory_sources['0'] = {
                            'inventory_source_id': 0,
                            'name': 'TBS Inventory'
                        };
                        data['inventory_sources'] = inventory_sources;
                    }
                    if (typeof inventory_sources[response.data.inventory_source_id] != "undefined") {
                        data['inventory_source_name'] = inventory_sources[response.data.inventory_source_id].name
                    }
                    self.setState(data, () => {
                        self.formatOrderDetails()
                    });
                    axios.get(API_ROOT + '/account/' + response.data.account_id)
                        .then(function (response) {
                            self.setState({
                                credit: response.data.credit.amount,
                                mo_outstanding: response.data.mo_outstanding
                            });
                        })
                        .catch(function (error) {
                            if (error.response.status === 401) {
                                self.props.userSignOut()
                            } else {
                                Swal("Error", error.response.data.Message, "error");
                            }
                        });
                })
                .catch(function (error) {
                    console.log(error)
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    }

    formatOrderDetails() {
        var payment_method_print = "";
        if (this.state.payment_method == 1) {
            if (this.state.card_type != null) {
                payment_method_print = this.state.card_type.charAt(0).toUpperCase() + this.state.card_type.slice(1) + ' ****' + this.state.last_four;
            } else {
                payment_method_print = 'Unknown Card';
            }
        } else if (this.state.payment_method == 3) {
            payment_method_print = "EFT";
        } else if (this.state.payment_method == 6) {
            payment_method_print = "E-Transfer";
        } else if (this.state.payment_method == 4) {
            payment_method_print = "Shopify";
        } else {
            payment_method_print = "Cheque";
        }
        this.setState({
            loading: false,
            payment_method_print: payment_method_print,
        });
    }

    // Update Input Field
    updateInput = (event) => {
        this.setState({[event.target.name]: event.target.value});
        if (event.target.name != "manual_payment_amount" && event.target.name != "cheque_number") {
            this.saveOrder({[event.target.name]: event.target.value});
        }
    };

    // Changing inventory source
    editInventorySource = () => {
        var inventory_source_name = "- Not Selected -"
        if (typeof this.state.inventory_sources[this.state.inventory_source_id] != "undefined") {
            inventory_source_name = this.state.inventory_sources[this.state.inventory_source_id].name
        }
        this.setState({
            dropdown_inventory_source_print: inventory_source_name,
            select_inventory_source: 1
        })
    };
    changeInventorySource = (inventory_source_id) => {
        var notes = this.state.notes
        /* if (notes!=""){
             notes+="\n"
         }
         notes += "*Delivery method changed from \""+this.state.dropdown_inventory_source_print+"\" to \""+this.state.inventory_sources[inventory_source_id].name+"\" by "+this.props.first_name+" "+this.props.last_name+" on "+moment().format('MMM. D, YYYY')+" at "+moment().format('h:mm A');*/
        var inventory_source_name = "- Not Selected -"
        if (typeof this.state.inventory_sources[this.state.inventory_source_id] != "undefined") {
            inventory_source_name = this.state.inventory_sources[inventory_source_id].name
        }
        var params = {
            inventory_source_id: inventory_source_id,
            select_inventory_source: null,
            inventory_source_name: inventory_source_name,
            notes: notes
        };
        this.setState(params);
        this.saveOrder(params);
    };

    // Changing delivery status
    editDeliveryStatus = () => {
        this.setState({
            dropdown_delivery_status_print: this.state.delivery_statuses[this.state.shipping_status],
            select_delivery_status: this.state.shipping_status
        })
    };
    changeDeliveryStatus = (shipping_status) => {
        var params = {
            shipping_status: shipping_status,
            select_delivery_status: null
        };
        this.setState(params);
        this.saveOrder(params);
    };

    // On update Est Delivery Date
    updateEstDate(event, picker) {
        event.preventDefault();
        if (event.type == "apply") {
            this.submitLCBOconfirmation(moment(picker.startDate).format('YYYY-MM-D'));
            this.setState({
                est_delivery_date: picker.startDate,
            });
            this.saveOrder({est_delivery_date: moment(picker.startDate).format('YYYY-MM-D')})
        }
    }

    // On update Delivery Date
    updateDate(event, picker) {
        event.preventDefault();
        if (event.type == "apply") {
            this.setState({
                select_delivery_date_status: null,
                delivery_date: picker.startDate,
            });
            this.saveOrder({delivery_date: picker.startDate})
        }
    }

    editDeliveryDate = () => {
        this.setState({
            select_delivery_date_status: true
        })
    };

    markDeliveryToday = (event) => {
        event.preventDefault();
        var params = {
            shipping_status: 2,
            select_delivery_status: null,
            select_delivery_date_status: null,
            est_delivery_date: moment()
        };
        this.submitLCBOconfirmation(moment());
        this.setState(params);
        this.saveOrder(params);
    };

    markDelivered = (event) => {
        event.preventDefault();
        var params = {
            shipping_status: 3,
            select_delivery_status: null,
            delivery_date: moment()
        };
        this.setState(params);
        this.saveOrder(params);
    };

    // Changing payment status
    editPaymentStatus = () => {
        this.setState({select_payment_status: this.state.is_paid})
    };
    cancelPaymentStatus = () => {
        this.setState({select_payment_status: null})
    };
    savePaymentStatus = () => {
        var notes = this.state.notes;
        if (notes == null) {
            notes = "";
        }
        if (this.state.select_payment_status == 1) {
            var manual_payment_method_id = this.state.manual_payment_method_id;
            var manual_payment_date_print = moment().format('MMM. D, YYYY') + " at " + moment().format('h:mm A');
            var manual_payment_name = this.props.first_name + " " + this.props.last_name;
        } else {
            var manual_payment_method_id = null;
            var manual_payment_date_print = null;
            var manual_payment_name = null;
        }
        if (notes != "") {
            notes += "\n"
        }
        var payment_status = {0: "Not Paid", 1: "Paid", 4: "Bad Debt", 5: "Promo"};
        notes += "*Status changed to " + payment_status[this.state.select_payment_status] + " by " + this.props.first_name + " " + this.props.last_name + " on " + moment().format('MMM. D, YYYY') + " at " + moment().format('h:mm A');
        var params = {
            select_payment_status: null,
            is_paid: this.state.select_payment_status,
            manual_payment_method_id: manual_payment_method_id,
            manual_payment_date_print: manual_payment_date_print,
            manual_payment_name: manual_payment_name,
            total_total: (this.state.total - this.state.credit_applied),
            notes: notes
        };
        this.setState(params, () => {
            this.formatOrderDetails()
        });
        this.saveOrder(params);
    };
    addPayment = () => {
        if (this.state.manual_payment_method_id == null) {
            Swal("Error", "Please select a payment method", "error");
        } else if (parseFloat(this.state.manual_payment_amount) < 0.01) {
            Swal("Error", "Please enter a positive payment amount", "error");
        } else if (this.state.manual_payment_method_id == 0 && parseFloat(this.state.manual_payment_amount) > parseFloat(this.state.credit)) {
            Swal("Error", "The entered payment amount exceeds the current account credit balance ($" + this.state.credit + ")", "error");
        } else {
            var cheque_number = this.state.cheque_number;
            if (this.state.manual_payment_method_id != 4) {
                cheque_number = "";
            }
            var new_payment = {
                payment_method: this.state.manual_payment_method_id,
                cheque_number: cheque_number,
                amount: Number(this.state.manual_payment_amount).toFixed(2),
                payment_date: moment()
            };
            var payments = this.state.payments;
            payments.push(new_payment);
            var self = this;
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/order/' + this.state.order_id + "/payment", new_payment)
                .then(function () {
                    self.setState({
                        manual_payment_method_id: null,
                        manual_payment_amount: 0,
                        record_payment_status: 0,
                        payments: payments,
                    });
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    self.loadOrder(self.state.order_id);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    };
    changePaymentStatus = (select_payment_status) => {
        this.setState({manual_payment_method_id: true});
        this.setState({
            select_payment_status: select_payment_status,
        })
    };
    addPaymentStatus = (record_payment_status) => {
        this.setState({
            record_payment_status: record_payment_status,
        })
    };
    changeManualPaymentMethod = (manual_payment_method_id) => {
        this.setState({
            manual_payment_method_id: manual_payment_method_id,
        })
    };

    // Save any details
    saveOrder(params) {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/order/' + this.props.match.params.order_id, params)
            .then(function () {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    submitLCBOconfirmation(est_delivery_date) {
        if (this.state.account_type == 3 && this.state.est_delivery_date == null && this.state.reference_id != null) {
            var self = this;
            axios.defaults.withCredentials = true;
            axios.put(API_ROOT + '/order/' + this.props.match.params.order_id + '/confirm/' + encodeURIComponent(moment(est_delivery_date).format('M-DD-YYYY')), {timeout: 5})
                .then(function (response) {
                    if (response.data.message == 'Successfully confirmed') {
                        toast.success("LCBO Confirmation Submitted", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    } else {
                        Swal("LCBO Error", response.data.message, "error");
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    }

    getDeliverySlip = function () {
        this.setState({downloading: true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/order/' + this.state.order_id + "/delivery_slip")
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'Delivery Slip - Order #' + self.state.order_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading: false});
            });
    };
    getPO = function () {
        this.setState({downloading: true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/order/' + this.state.order_id + "/po")
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'PO #' + self.state.order_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading: false});
            });
    };
    getPDF = function () {
        this.setState({downloading: true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/order/' + this.state.order_id + "/invoice")
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'Order #' + self.state.order_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading: false});
            });
    };

    voidOrder = function () {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "This order will be marked as void, inventory will no longer be deducted from the delivery source, and the order will not be included in sales reports.",
            showCancelButton: true,
            confirmButtonText: 'Yes, void now',
            cancelButtonText: 'No',
        }).then((isConfirm) => {
            if (isConfirm.value) {
                axios.delete(API_ROOT + '/order/' + self.state.order_id)
                    .then(function (response) {
                        self.setState({
                            void_date: moment().format("YYYY-MM-DD")
                        });
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            }
            self.setState({
                modal: !self.state.modal
            });
        });
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="../orders">Order History</Link>&nbsp;/&nbsp;{(this.state.credit_order_id > 0 ? "Credit Memo" : "Order")} #{this.state.order_id}
                    <div className="ml-auto">
                        <span
                            className={((this.state.company_id == 1 || this.state.company_id == 7 || this.state.company_id == 15 || this.state.company_id == 16) && this.state.shipping_status != 3 ? "btn btn-info mr-1" : "d-none")}
                              onClick={this.getDeliverySlip.bind(this)}
                              disabled={this.state.downloading}>
                            <i class="fas fa-truck-loading"></i> {(this.state.credit_order_id > 0 ? "Pickup Slip" : "Delivery Slip")}
                        </span>
                        <a className={(this.state.order_id >= 6426 && this.state.account_type == 5 ? "btn btn-info mr-1" : "d-none")} target="_blank"
                           href={"/grocery_bols/" + this.state.order_id + ".pdf"}>
                            <i className="fas fa-paperclip"></i> Grocery BOL
                        </a>
                        <span className={(this.state.company_id == 1 || this.state.company_id == 7 || this.state.company_id == 15 || this.state.company_id == 16) ? "" : "d-none"}>
                            <Button color="info" onClick={this.getPDF.bind(this)} disabled={this.state.downloading}>
                                <i className={(this.state.downloading ? "fa fa-spinner fa-spin" : "fa fa-download")}></i> Printable Receipt</Button>
                        </span>
                        <span
                            className={(this.state.company_id != 1 && this.state.company_id != 7 && this.state.company_id != 15 && this.state.company_id != 16 ? "btn btn-info mr-1" : "d-none")}
                              onClick={this.getPO.bind(this)}>
                            <i className="fas fa-download"></i> Download PO
                        </span>
                        <span className={(this.state.credit_memo > 0 || this.state.credit_order_id > 0 ? "d-none" : "")}>
                            <OrderReturn items={this.state.items} order_id={this.state.order_id} account_id={this.state.account_id}
                                         inventory_source_id={this.state.inventory_source_id} payment_method={this.state.payment_method}
                                         inventory_sources={this.state.inventory_sources} total={this.state.total} history={this.props.history}
                            />
                        </span>
                        <Button color="danger" onClick={this.voidOrder.bind(this)} className={(this.state.void_date != null ? "d-none" : "ml-1")}>
                            <i className="fa fa-trash-alt"></i> Void
                        </Button>
                    </div>
                </div>
                <div className={(this.state.void_date == null ? "d-none" : "alert alert-danger text-center text-bold")}>
                    This has order was marked as void on {moment(this.state.void_date).format("YYYY-MM-DD")}
                </div>
                <div className={(this.state.credit_memo > 0 ? "p-2 alert alert-warning text-center text-bold" : "d-none")}>
                    <i className="fas fa-undo mr-1"></i> A credit memo has been issued from this order. See <Link to={"../order/" + this.state.credit_memo} className="text-light">Credit
                    Memo
                    # {this.state.credit_memo}</Link>
                </div>
                <div className={(this.state.credit_order_id > 0 ? "p-2 alert alert-info text-center text-bold" : "d-none")}>
                    <i className="fas fa-cash-register mr-1"></i> This credit memo relates to <Link to={"../order/" + this.state.credit_order_id} className="text-light">Order
                    # {this.state.credit_order_id}</Link>
                </div>
                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal">
                                    <FormGroup row>
                                        <Col md="4" sm="5">Account:</Col>
                                        <Col md="8" sm="7">
                                            <strong><Link to={"../account/" + this.state.account_id}>{this.state.name}</Link></strong>
                                            <div>{this.state.address1}, {this.state.city}, {this.state.province}</div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Date/Time Ordered:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.date_ordered}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Placed By:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.first_name} {this.state.last_name}</strong>
                                        </Col>
                                    </FormGroup>
                                    <div className="bg-light pl-2 pt-2 pr-1" style={{"marginLeft": "-7px"}}>
                                        <FormGroup row>
                                            <Col md="4" sm="5">Delivery Status:</Col>
                                            <Col md="8" sm="7">
                                                <span className={(this.state.void_date == null && this.state.shipping_status == 2 ? "" : "d-none")}>
                                                    <button className="btn btn-xs btn-primary float-right" onClick={this.markDelivered}><i className="fa fa-check"></i> Mark as Delivered</button>
                                                </span>
                                                <span className={(this.state.void_date == null && this.state.shipping_status <= 1 ? "" : "d-none")}>
                                                    <button className="btn btn-xs btn-primary float-right" onClick={this.markDeliveryToday}><i className="fa fa-shipping-fast"></i> Mark as Delivery Today</button>
                                                </span>
                                                <div className={(
                                                    this.state.select_delivery_status != null ||
                                                    ((this.state.shipping_status == 9 && localStorage.getItem('permissions') != "ALL" && localStorage.getItem('permissions').indexOf(',CL,') == -1) ? true : false)
                                                        ? "d-none" : "")}
                                                >
                                                    <DeliveryStatus shipping_status={this.state.shipping_status} company_id={this.state.company_id}/>
                                                    <Button size="xs" color="default" className="ml-1 far fa-edit" onClick={this.editDeliveryStatus}
                                                            class={(this.state.void_date == null ? "" : "d-none")}></Button>
                                                </div>
                                                <div className={(
                                                    ((this.state.shipping_status == 9 && localStorage.getItem('permissions') != "ALL" && localStorage.getItem('permissions').indexOf(',CL,') == -1) ? true : false)
                                                        ? "" : "d-none")}
                                                >
                                                    <DeliveryStatus shipping_status={this.state.shipping_status} company_id={this.state.company_id}/>
                                                </div>
                                                <UncontrolledButtonDropdown className={(this.state.void_date != null || this.state.select_delivery_status == null ? "d-none" : "")}>
                                                    <DropdownToggle color="secondary" caret size="sm">
                                                        {this.state.dropdown_delivery_status_print}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.delivery_statuses).map(function (delivery_status, key) {
                                                            return (
                                                                <DropdownItem key={delivery_status}
                                                                              onClick={() => this.changeDeliveryStatus(delivery_status)}>{this.state.delivery_statuses[delivery_status]}</DropdownItem>
                                                            )
                                                        }, this)}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </Col>
                                        </FormGroup>
                                        <div>
                                            <FormGroup row>
                                                <Col md="4" sm="5">Est. Delivery Date:</Col>
                                                <Col md="8" sm="7">
                                                    <span className={(this.state.void_date == null && this.state.shipping_status != 3 ? "" : "d-none")}>
                                                        <DateRangePicker
                                                            singleDatePicker={true}
                                                            date={(this.state.est_delivery_date == null ? moment() : this.state.est_delivery_date)}
                                                            onEvent={this.updateEstDate}
                                                            autoUpdateInput={true}
                                                            minDate={moment()}
                                                        >
                                                            <div className="btn btn-secondary">
                                                                {(this.state.est_delivery_date == null ? '- Not Selected - ' : this.state.est_delivery_date.format('MMM. D, YYYY'))}
                                                                <span className="fas fa-caret-down fa-sm"></span>
                                                            </div>
                                                        </DateRangePicker>
                                                    </span>
                                                    <span className={(this.state.shipping_status == 3 ? "text-bold" : "d-none")}>
                                                         {(this.state.est_delivery_date == null ? '- Not Selected - ' : this.state.est_delivery_date.format('MMM. D, YYYY'))}
                                                    </span>
                                                    <span
                                                        className={((this.state.void_date == null && this.state.shipping_status >= 2 && this.state.est_delivery_date == null) ? "text-danger text-bold ml-2" : "d-none")}><i
                                                        className="fas fa-exclamation-triangle fa-lg"></i> Please Select Est. Delivery Date</span>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                        <div className={(this.state.shipping_status == 3 ? "" : "d-none")}>
                                            <FormGroup row>
                                                <Col md="4" sm="5">Delivery Date:</Col>
                                                <Col md="8" sm="7">
                                                    <span className={(this.state.void_date == null && this.state.select_delivery_date_status != null ? "" : "d-none")}>
                                                        <DateRangePicker
                                                            singleDatePicker={true}
                                                            date={(this.state.delivery_date == null ? moment() : this.state.delivery_date)}
                                                            onEvent={this.updateDate}
                                                            autoUpdateInput={true}
                                                            name="delivery_date"
                                                        >
                                                            <div className="btn btn-secondary">
                                                                {(this.state.delivery_date == null ? '- Not Selected - ' : this.state.delivery_date.format('MMM. D, YYYY'))}
                                                                <span className="fas fa-caret-down fa-sm"></span>
                                                            </div>
                                                        </DateRangePicker>
                                                    </span>
                                                    <span className={(this.state.select_delivery_date_status == null ? "text-bold" : "d-none")}>
                                                        {(this.state.delivery_date == null ? '- Not Selected - ' : this.state.delivery_date.format('MMM. D, YYYY'))}
                                                        <Button size="xs" color="default" className="ml-1 far fa-edit" onClick={this.editDeliveryDate}></Button>
                                                    </span>
                                                    <span
                                                        className={((this.state.shipping_status == 3 && this.state.delivery_date == null) ? "text-danger text-bold ml-2" : "d-none")}><i
                                                        className="fas fa-exclamation-triangle fa-lg"></i> Please Select Delivery Date</span>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <FormGroup row>
                                                <Col md="4" sm="5">Delivery By:</Col>
                                                <Col md="8" sm="7">
                                                    <span className={(this.state.select_inventory_source != null ? "d-none" : "text-bold")}>
                                                        {this.state.inventory_source_name}
                                                        <span className={(this.state.void_date == null && this.state.shipping_status != 3 ? "" : "d-none")}>
                                                            <Button size="xs" color="default" className="ml-1 far fa-edit" onClick={this.editInventorySource}></Button>
                                                        </span>
                                                    </span>
                                                    <UncontrolledButtonDropdown
                                                        className={(this.state.void_date != null || this.state.select_inventory_source == null ? "d-none" : "")}>
                                                        <DropdownToggle color="secondary" caret>
                                                            {this.state.dropdown_inventory_source_print}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                                                return (
                                                                    <DropdownItem key={inventory_source_id}
                                                                                  onClick={() => this.changeInventorySource(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                                                )
                                                            }, this)}
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                    <span
                                                        className={((this.state.void_date == null && this.state.shipping_status >= 1 && this.state.inventory_source_id == 0 && this.state.credit_order_id == null && this.state.account_type != 2) ? "text-danger text-bold ml-2" : "d-none")}><i
                                                        className="fas fa-exclamation-triangle fa-lg"></i> Please Select Driver</span>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                        <FormGroup row>
                                            <Col md="4" sm="5">Delivery Instructions:
                                                <div className="small text-muted">(Visible to customer)</div>
                                            </Col>
                                            <Col md="8" sm="7">
                                                <Input type="textarea" name="delivery_instructions" className={(this.state.shipping_status != 3 ? "" : "d-none")}
                                                       style={{height: '60px'}} onChange={this.updateInput.bind(this)} value={this.state.delivery_instructions}></Input>
                                                <span className={(this.state.shipping_status == 3 ? "text-bold" : "d-none")}>
                                                    {this.state.delivery_instructions}
                                                </span>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Internal Notes:
                                            <div className="small text-muted">(Not visible to customer)</div>
                                        </Col>
                                        <Col md="8" sm="7">
                                            <Input type="textarea" name="notes" style={{height: '130px'}} onChange={this.updateInput.bind(this)} value={this.state.notes}></Input>
                                        </Col>
                                    </FormGroup>
                                </form>
                            </Col>
                            <Col lg="6">
                                <p className="lead bb">Billing</p>
                                <div className="form-horizontal">
                                    <FormGroup row className="mb-2">
                                        <Col md="4" sm="5">Subtotal:</Col>
                                        <Col md="8" sm="7">
                                            <strong>${this.state.subtotal}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="mb-2">
                                        <Col md="4" sm="5">+Tax:</Col>
                                        <Col md="8" sm="7">
                                            <strong>${this.state.tax}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="mb-2">
                                        <Col md="4" sm="5">+Deposit:</Col>
                                        <Col md="8" sm="7">
                                            <strong>${this.state.deposit}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="mb-2">
                                        <Col md="4" sm="5">=Total:</Col>
                                        <Col md="8" sm="7">
                                            <strong><u>${parseFloat(this.state.total).toFixed(2)}</u></strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className={(this.state.credit_order_id > 0 ? "d-none" : "mb-2")}>
                                        <Col md="4" sm="5">-Paid:</Col>
                                        <Col md="8" sm="7">
                                            <strong className={(this.state.total_paid > 0 ? "text-success" : "")}>(${parseFloat(this.state.total_paid).toFixed(2)})</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className={(this.state.credit_order_id > 0 ? "d-none" : "mb-2")}>
                                        <Col md="4" sm="5">=Total Payable:</Col>
                                        <Col md="8" sm="7">
                                            <strong className="text-dark"><u>${this.state.payable.toFixed(2)}</u></strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className={(this.state.purchase_order == null ? "d-none" : "mb-2")}>
                                        <Col md="4" sm="5">Purchase Order:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.purchase_order}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className={(this.state.credit_order_id > 0 ? "d-none" : "mb-2")}>
                                        <Col md="4" sm="5">Payment Method:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.payment_method_print}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className={(this.state.credit_order_id > 0 ? "d-none" : "mb-2")}>
                                        <Col md="4" sm="5">Status:</Col>
                                        <Col md="8" sm="7">
                                            <div className={(this.state.select_payment_status == null ? "" : "d-none")}>
                                                <PaymentStatus is_paid={this.state.is_paid}/>
                                                <Button size="xs" color="default" className={(this.state.payment_method == 1 ? "d-none" : "ml-1 far fa-edit")}
                                                        onClick={this.editPaymentStatus}></Button>
                                            </div>
                                            <ButtonGroup className={(this.state.select_payment_status == null ? "d-none" : "")}>
                                                <Button size="sm" color={(this.state.select_payment_status == 0 ? "warning" : "secondary")}
                                                        className={(this.state.payable <= 0 ? "d-none" : "")}
                                                        onClick={() => this.changePaymentStatus(0)}>Not Paid</Button>
                                                <Button size="sm" color={(this.state.select_payment_status == 1 ? "warning" : "secondary")}
                                                        className={(this.state.payable <= 0 ? "" : "d-none")}
                                                        onClick={() => this.changePaymentStatus(1)}>Paid</Button>
                                                <Button size="sm" color={(this.state.select_payment_status == 5 ? "info" : "secondary")}
                                                        onClick={() => this.changePaymentStatus(5)}
                                                        disabled={(this.state.mo_outstanding > 0 && localStorage.getItem('staff_id') != 45 && localStorage.getItem('staff_id') != 60 ? true : false)}
                                                >Mark Promo</Button>

                                                <Button size="sm" color={(this.state.select_payment_status == 4 ? "dark" : "secondary")}
                                                        onClick={() => this.changePaymentStatus(4)}>Mark Bad Debt</Button>
                                            </ButtonGroup>
                                        </Col>
                                    </FormGroup>
                                    <Row className={(this.state.select_payment_status == null ? "d-none" : "")}>
                                        <Col md="4" sm="5"></Col>
                                        <Col md="8" sm="7">
                                            <Button color="primary" size="sm" disabled={this.state.manual_payment_method_id == null && this.state.select_payment_status == 1}
                                                    onClick={this.savePaymentStatus}
                                                    className={(this.state.select_payment_status != this.state.is_paid ? "mr-2" : "d-none")}>
                                                <i className="fas fa-check"></i> Update Status
                                            </Button>
                                            <Button color="secondary" size="sm" onClick={this.cancelPaymentStatus} id="cancel_payment_status">
                                                <i className="fas fa-undo"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="cancel_payment_status">Cancel editing mode</UncontrolledTooltip>
                                        </Col>
                                    </Row>

                                    <div className="lead bb">
                                        {(this.state.credit_order_id > 0 ? "Refunds" : "Payments")}
                                        <Button size="xs"
                                                className={(this.state.credit_order_id > 0 || this.state.record_payment_status == 1 || this.state.is_paid != 0 ? "d-none" : "float-right")}
                                                color="primary" onClick={() => this.addPaymentStatus(1)}><i className="far fa-money-bill-alt"></i> Record Payment</Button>
                                    </div>
                                    <div className={(this.state.record_payment_status == 1 ? "" : "d-none")}>
                                        <FormGroup className="bg-light pr-3 pl-3 pb-2 mb-0">
                                            <Row>
                                                <Col className="text-bold">
                                                    Record Payment
                                                </Col>
                                            </Row>
                                            <Row className="pt-2">
                                                <Col md="4" sm="5">
                                                    Method:
                                                </Col>
                                                <Col md="8" sm="7">
                                                    <ButtonGroup>
                                                        <Button size="sm" color={(this.state.manual_payment_method_id == 0 ? "info" : "secondary")}
                                                                className={(this.state.credit > 0 ? "" : "d-none")}
                                                                onClick={() => this.changeManualPaymentMethod(0)}>Account Credit</Button>
                                                        <Button size="sm" color={(this.state.manual_payment_method_id == 4 ? "info" : "secondary")}
                                                                onClick={() => this.changeManualPaymentMethod(4)}>Cheque</Button>
                                                        <Button size="sm" color={(this.state.manual_payment_method_id == 5 ? "info" : "secondary")}
                                                                onClick={() => this.changeManualPaymentMethod(5)}>Cash</Button>
                                                        <Button size="sm" color={(this.state.manual_payment_method_id == 1 ? "info" : "secondary")}
                                                                onClick={() => this.changeManualPaymentMethod(1)}>Credit Card</Button>
                                                        <Button size="sm" color={(this.state.manual_payment_method_id == 3 ? "info" : "secondary")}
                                                                onClick={() => this.changeManualPaymentMethod(3)}>EFT</Button>
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                            <Row className={(this.state.manual_payment_method_id == 4 ? "pt-2" : "d-none")}>
                                                <Col md="4" sm="5">
                                                    Cheque Num:
                                                </Col>
                                                <Col md="8" sm="7">
                                                    <Input type="text" size="xs" className="mb-2" name="cheque_number" value={this.state.cheque_number}
                                                           onChange={this.updateInput.bind(this)}/>
                                                </Col>
                                            </Row>
                                            <Row className="pt-2">
                                                <Col md="4" sm="5">
                                                    Amount:
                                                </Col>
                                                <Col md="8" sm="7">
                                                    <Input type="text" size="xs" className="mb-2" name="manual_payment_amount"
                                                           value={this.state.manual_payment_amount}
                                                           onChange={this.updateInput.bind(this)} placeholder="amount"/>
                                                    <Button color="primary" size="xs"
                                                            disabled={this.state.manual_payment_method_id == null && this.state.select_payment_status == 1}
                                                            onClick={this.addPayment}>
                                                        <i className="fas fa-check"></i> Add Payment
                                                    </Button>
                                                    <Button color="secondary" size="xs" onClick={() => this.addPaymentStatus(0)} id="cancel_add_payment">
                                                        <i className="fas fa-undo"></i>
                                                    </Button>
                                                    <UncontrolledTooltip placement="bottom" target="cancel_add_payment">Cancel adding payment</UncontrolledTooltip>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </div>
                                    <Table responsive>
                                        <tbody>
                                        {this.state.payments.map(function (item, key) {
                                            if (item.amount != 0) {
                                                return (
                                                    <tr key={key}>
                                                        <td>&bull; ${(item.amount)}</td>
                                                        <td>{this.state.payment_methods[item.payment_method]}<i
                                                            className="text-muted">{(item.response == null ? "" : " " + item.response)}{(item.payment_method != 4 ? "" : " (#" + item.cheque_number + ")")}</i>
                                                        </td>
                                                        <td>{moment(item.payment_date).format("MMM D, YYYY")}</td>
                                                    </tr>
                                                )
                                            }
                                        }, this)}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <p className="lead bb">Order Items</p>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Cost/Each</th>
                                <th>Quantity</th>
                                <th>Subtotal</th>
                                <th className="d-none d-sm-table-cell">+Deposit</th>
                                <th className="d-none d-sm-table-cell">+Tax</th>
                                <th className="d-none d-sm-table-cell">=Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.items.map(function (item, key) {
                                return (
                                    <tr key={key}>
                                        <td>
                                            <a href={'../img/products/' + item.product_id + '.jpg'} className="float-left mr-3 d-none d-sm-block" data-lity>
                                                <img src={'https://order.umbrel.thewebpeople.ca/img/products/' + item.product_id + '_sm.png'} style={{height: '40px'}}></img>
                                            </a>
                                            {item.name}
                                        </td>
                                        <td>${(item.subtotal / item.quantity)}</td>
                                        <td>{item.quantity}</td>
                                        <td>${item.subtotal.toFixed(2)}</td>
                                        <td className="d-none d-sm-table-cell">${item.deposit.toFixed(2)}</td>
                                        <td className="d-none d-sm-table-cell">${item.tax.toFixed(2)}</td>
                                        <td className="d-none d-sm-table-cell">${((item.subtotal + item.tax + item.deposit)).toFixed(2)}</td>
                                    </tr>
                                )
                            }, this)}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </ContentWrapper>
        );
    }

}

export default OrderDetails;
