import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PaymentStatus from "../Orders/PaymentStatus";
import AccountStatus from '../Accounts/AccountFunctions';
import ToggleState from '../Common/ToggleState';
import HeaderRun from './Header.run'
import axios from 'axios';
import {API_ROOT} from "../../api-config";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_term: "",
            search_loading: false,
            search_results: {accounts: [], orders: [], bols: [], term: ""},
            typingTimeout: 0
        }
    }

    componentDidMount() {
        HeaderRun();
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
    }

    enterSearch = (event) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search_term: event.target.value,
            search_loading: true,
            typingTimeout: setTimeout(function () {
                console.log('searching: ' + self.state.search_term)
                axios.get(API_ROOT + '/search/' + self.state.search_term)
                    .then(function (response) {
                        if (response.data.term.trim() == self.state.search_term.trim()) { // make sure not delayed response
                            self.setState({
                                search_results: response.data,
                                search_loading: false,
                            });
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            self.setState({
                                search_results: {accounts: [], orders: [], bols: [], term: ""},
                                loading: false
                            });
                        }
                    });
            }, 200)
        });
    };

    goTo = (dir, id) => {
        this.setState({
            search_term: "",
            search_loading: false,
            search_results: {accounts: [], orders: [], bols: [], term: ""},
        });
        this.props.history.push("/" + dir + "/" + id);
    };

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/dashboard">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo.png" alt="Logo" style={{"maxHeight": "40px"}}/>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" style={{"maxHeight": "40px"}} src="./img/logo-single.png" alt="BevOps"/>
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <ToggleState state="aside-toggled" nopersist={true}>
                                <a href="" className="nav-link sidebar-toggle d-md-none">
                                    <em className="fas fa-bars"></em>
                                </a>
                            </ToggleState>
                        </li>
                    </ul>

                    <ul className="navbar-nav flex-row">
                        <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier fa-lg"></em>
                            </a>
                        </li>
                        { /* START Search form */}
                        <form className="navbar-form" role="search" action="search.html" autocomplete="off">
                            <div className="form-group">
                                <input className="form-control font-weight-bold" type="text" name="search_term"
                                       placeholder="Order #, BOL #, Account Name, License #, Address, City, Manager, PO #"
                                       style={{"fontSize": "1rem"}} onChange={(event) => this.enterSearch(event)} value={this.state.search_term}/>
                                <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                            </div>
                            <button className="d-none" type="submit">Submit</button>
                        </form>
                        { /* END Search form */}
                    </ul>
                    { /* END Left navbar */}
                </nav>
                { /* END Top Navbar */}
                <div style={{
                    "background": "#fff",
                    "position": "absolute",
                    "zIndex": "9000",
                    "top": "-1000px",
                    "marginTop": "55px",
                    "left": "0px",
                    "maxWidth": "600px",
                    "maxHeight": "calc(100vh - 70px)",
                    "transition": "transition: all .3s",
                    "boxShadow": "7px 6px 20px -1px rgba(0,0,0,0.24)",
                    "overflowY": "auto",
                    "borderRadius": "0px 0px 10px 10px"
                }} className="w-100 search_results">
                    <div className={(this.state.search_loading && this.state.search_term != "" ? "text-center p-2" : "d-none")}>
                        <i className="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                    <div
                        className={(!this.state.search_loading && this.state.search_term != "" && this.state.search_results.accounts.length == 0 && this.state.search_results.orders.length == 0 && this.state.search_results.bols.length == 0 ? "text-center p-2" : "d-none")}>
                        No matches found
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.accounts.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">ACCOUNTS</div>
                        {this.state.search_results.accounts.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const name = item.name.toString().replace(reg, '<b class="text-dark">$1</b>');
                            const license_num = item.license_num.toString().replace(reg, '<b class="text-dark">$1</b>');
                            const address = item.address1.toString().replace(reg, '<b class="text-dark">$1</b>');
                            const city = item.city.toString().replace(reg, '<b class="text-dark">$1</b>');
                            const manager = item.manager_name.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "account", item.account_id)} className="p-1 pl-2 border-top search_row">
                                    <div><span dangerouslySetInnerHTML={{__html: name}}></span></div>
                                    <div className="small"><span dangerouslySetInnerHTML={{__html: license_num}}></span> &nbsp;|&nbsp; <span
                                        dangerouslySetInnerHTML={{__html: address}}></span>, <span dangerouslySetInnerHTML={{__html: city}}></span><span
                                        className={(item.manager_name != "" ? "" : "d-none")}> &nbsp;|&nbsp; <span
                                        dangerouslySetInnerHTML={{__html: manager}}></span></span> &nbsp;&nbsp;
                                        <AccountStatus status={item.status}/>
                                    </div>
                                </div>
                            )
                        }, this)}
                        <div
                            className={(this.state.search_results.accounts.length == 250 ? "text-center p-2 bg-danger-light" : "d-none")}>
                            Additional accounts not shown. Keep typing to narrow down results.
                        </div>
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.orders.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">ORDERS</div>
                        {this.state.search_results.orders.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const order_id = item.order_id.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "order", item.order_id)} className="p-1 pl-2 border-top search_row">
                                    <div>ORD-<span dangerouslySetInnerHTML={{__html: order_id}}></span></div>
                                    <div className="small">{item.name} &nbsp;|&nbsp; {item.date_ordered} &nbsp;|&nbsp; ${item.total.toLocaleString("en-US")} &nbsp;&nbsp;
                                        <PaymentStatus is_paid={item.is_paid} void_date={item.void_date}/></div>
                                </div>
                            )
                        }, this)}
                    </div>
                    <div className={(!this.state.search_loading && this.state.search_results.bols.length > 0 ? "" : "d-none")}>
                        <div className="text-bold bg-dark text-light p-1 pl-2">BOLS</div>
                        {this.state.search_results.bols.map(function (item, key) {
                            var reg = new RegExp('(' + this.state.search_term + ')', 'gi');
                            const bol_id = item.bol_id.toString().replace(reg, '<b class="text-dark">$1</b>');
                            return (
                                <div key={key} onClick={this.goTo.bind(this, "bol", item.bol_id)} className="p-1 pl-2 border-top search_row">
                                    <div>BOL-<span dangerouslySetInnerHTML={{__html: bol_id}}></span></div>
                                    <div className="small">{item.name} &nbsp;|&nbsp; {item.date_ordered}</div>
                                </div>
                            )
                        }, this)}
                    </div>
                </div>
            </header>
        );
    }

}

export default withRouter(Header);
