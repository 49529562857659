import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, Row, Col, Form, FormGroup, Label, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import {getInventorySources, getProducts} from '../Inventory/InventoryFunctions.js';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");


class CreatePickup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            products: {},
            downloading: false,
            products_sort: [],
            notes: '',
            loading: true,
            processing: false,
            inventory_sources: {"0": {name: "loading"}},
            inventory_source_id: 0,
            destination_id: 0,
            date_ordered: moment(),
            items: []
        };
    }

    componentDidMount() {
        document.title = "Pickup # " + this.props.match.params.pickup_id + " | BevOps";
        var self = this;
        axios.defaults.withCredentials = true;
        getInventorySources(null, function (inventory_sources) {
            getProducts(function (products) {
                axios.get(API_ROOT + '/pickup/' + self.props.match.params.pickup_id)
                    .then(function (response) {
                        var products_sort = [];
                        for (const product_id of Object.keys(products)) {
                            products[product_id].ordered = 0
                            products_sort.push({id: product_id, name: products[product_id].name})
                        }
                        products_sort = products_sort.sort((a, b) => {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                return -1;
                            }
                            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        });
                        self.setState({
                            inventory_sources: inventory_sources,
                            products: products,
                            products_sort: products_sort,
                            inventory_source_id: response.data.inventory_source_id,
                            destination_id: response.data.destination_id,
                            items: response.data.items,
                            date_ordered: response.data.date_ordered,
                            loading: false
                        });
                    })
                    .catch(function (error) {
                        console.log(error)
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    });
            });
        });
    }

    getPDF = function () {
        this.setState({downloading: true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/pickup/' + this.props.match.params.pickup_id + "/download")
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'BevOps Pickup #' + props.match.params.pickup_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading: false});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading: false});
            });
    }

    render() {
        var self = this;
        console.log(this.state.inventory_sources)
        console.log(this.state.inventory_source_id)
        console.log(this.state.inventory_sources[this.state.inventory_source_id])
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="/pickup">Product Pickup Requests</Link>&nbsp;/ Pickup # {this.props.match.params.pickup_id}
                    <Button color="info" onClick={this.getPDF.bind(this)} disabled={this.state.downloading} className="ml-auto">
                        <i className={(this.state.downloading ? "fa fa-spinner fa-spin" : "fa fa-download")}></i> Printable Receipt
                    </Button>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className="card-body">
                        <Form>
                            <Row>
                                <Col>
                                    <div>
                                        <span className="font-weight-bold mb-0">Date/Time</span>: {moment(this.state.date_ordered).format("YYYY-MM-DD HH:MM A")}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col>
                                    <div>
                                        <span className="font-weight-bold mb-0">Source</span>: {this.state.inventory_sources[this.state.inventory_source_id].name}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col>
                                    <div>
                                        <span className="font-weight-bold mb-0">Requestor</span>: {this.state.inventory_sources[this.state.destination_id].name}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col>
                                    <div>
                                        <span className="font-weight-bold mb-0">Explanation</span>: {this.state.notes}
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <div className="table-responsive pt-2">
                            <table className="table">
                                <thead>
                                <tr className="bg-gray-lighter">
                                    <th>Product</th>
                                    <th>Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.items.map(function (item, key) {
                                    var product_id = item.product_id;
                                    var quantity = item.quantity;
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <img src={"https://order.umbrel.thewebpeople.ca/img/products/" + product_id + "_sm.png"} style={{width: "50px", height: "50px"}}
                                                     className="mr-1"></img>
                                                {this.state.products[product_id].name}
                                            </td>
                                            <td>
                                                {quantity}
                                            </td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (CreatePickup);
