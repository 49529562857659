import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Button,
    InputGroup,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    UncontrolledButtonDropdown,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    InputGroupAddon,
    Col,
    Row,
    Table,
    Label,
    Card,
    CardBody,
    Badge
} from 'reactstrap';
import {getProductsParent, getPricingCategories, getPricingHistory} from '../Products/ProductFunctions.js';
import {getCategories} from '../Categories/CategoryFunctions.js';
import {getFactors} from '../Common/Factors.js';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import {Link} from 'react-router-dom';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");

class ProductOverview extends React.Component {
    constructor(props) {
        super(props);
        window.ProductComponent = this;

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            products: {},
            loading: true,
            parent_name: "",
            display_photo: false,
            photo_hash: 0,
            pricing_history: {},
            // default product selection
            product: {
                name: "",
                product_category_id: null,
                product_parent_id: null,
                product_factor_id: null,
                sku: "",
                tbs_num: "",
                notes: "",
                deposit: 0,
                is_taxable: true,
                available: 0,
                account_id: null,
                qb_ids: []
            },
            // display
            dropdown_print: {
                category: "",
                parent: "",
                factor: "",
                available: ""
            },
            // default lists
            list: {
                type: [],
                parent: [],
                category: [],
                factor: [],
                pricing_categories: [],
                available: [1, 2, 3, 4, 5, 6, 7, 10, 15, 20, 50, 100, 500, 99999]
            },
            //
            new_price: {
                product_pricing_category_id: null,
                category_name: null,
                price: "",
                is_sale: 0,
                start_date: moment(),
                label_date: "",
                deposit: 0,
                is_taxable: true
            },
            accounts: [],
            modal: false,
            account_types: {
                0: "Special",
                1: "On-Premise",
                2: "TBS",
                3: "LCBO",
                4: "Individuals",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                9: "On-Premise, Cat B",
                10: "Duty-Free",
                11: "Convenience",
            },
            qbo_map: {0: "< General product SKU, auto-created as needed >",},
            owner_id: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.title = "Product | BevOps";
        var self = this;
        var list = self.state.list;
        getCategories(function (category) {
            list.category = category;
            getFactors(function (factor) {
                list.factor = factor;
                self.setState({list: list});
                self.getProductInfo();
            });
        });
        getPricingCategories(function (pricing_categories) {
            if (Object.keys(pricing_categories).length > 0) {
                var list = self.state.list;
                list.pricing_categories = pricing_categories;
                self.setState({list: list}, function () {
                    self.updatePricingHistory();
                });
            }
        });
        this.checkPhoto();
    }

    getProductInfo() {
        var self = this;
        getProductsParent(function (parents) {
            var list = self.state.list;
            list.parent = parents;
            self.setState({list: list, loading: false});
            var dropdown_print = self.state.dropdown_print;
            var product = self.state.product;
            Object.keys(parents).map(function (product_parent_id, key) {
                var parent = parents[product_parent_id];
                if (product_parent_id == self.props.match.params.product_parent_id) {
                    self.setState({parent_name: parent.name, owner_id: parent.product_owner_id});
                    Object.keys(parent.products).forEach(function (product_id) {
                        if (product_id == self.props.match.params.product_id) {
                            product = parent.products[product_id];
                            document.title = product.name + " | BevOps";
                            dropdown_print.category = list.category[product.product_category_id].name;
                            dropdown_print.parent = list.parent[product_parent_id].name;
                            dropdown_print.factor = list.factor[product.product_factor_id].name;
                            self.getAvailablePrint(product.available);
                        }
                    });
                    if (parent.product_owner_id == 1 || parent.product_owner_id == 7) {
                        axios.get(API_ROOT + '/qb_items/' + parent.product_owner_id)
                            .then(function (response) {
                                response.data[0] = "< General product SKU, auto-created as needed >";
                                self.setState({qbo_map: response.data});
                            })
                            .catch(function (error) {
                                if (error.response.status === 401) {
                                    self.props.userSignOut()
                                } else {
                                    Swal("Error", error.response.data.Message, "error");
                                }
                            });
                    }
                }
            });
            // set the parent if there is no product specified
            if (isNaN(self.props.match.params.product_id)) {
                dropdown_print.parent = list.parent[self.props.match.params.product_parent_id].name;
                product.product_parent_id = self.props.match.params.product_parent_id;
                product.name = list.parent[self.props.match.params.product_parent_id].name;
            }
            console.log(product)
            self.setState({product: product, dropdown_print: dropdown_print});
        });
    }

    updatePricingHistory() {
        var self = this;
        getPricingHistory(self.props.match.params.product_id, function (history) {
            if (history.length > 0) {
                var pricing_history = {};
                history.forEach(function (entry) {
                    var date = moment(entry.start_date).format('lll');
                    Object.keys(self.state.list.pricing_categories).map(function (category_id, key) { // set default values for the date if not already set
                        if (typeof pricing_history[date] == "undefined") {
                            pricing_history[date] = {};
                        }
                        if (typeof pricing_history[date][category_id] == "undefined") { // if the date or category are not defined
                            var line = {};
                            line.price = "";
                            line.deposit = 0;
                            line.is_sale = 0;
                            line.is_taxable = 0;
                            line.staff_id = null;
                            line.start_date = null;
                            pricing_history[date][category_id] = line;
                        }
                    });
                    pricing_history[date][entry.product_pricing_category_id] = entry;
                });
                self.setState({pricing_history: pricing_history});
            }
        });
    }

    checkPhoto() {
        var imageExists = require('image-exists');
        var self = this;
        var hash = Math.random().toString(36).substring(7);
        this.setState({photo_hash: hash}, () => {
            var src = `https://order.umbrel.thewebpeople.ca/img/products/${self.props.match.params.product_id}.jpg?${hash}`;
                imageExists(src, function (exists) {
                    if (exists) {
                        self.setState({display_photo: true});
                    }
                });
            }
        );
    }

    handleChange(event) {
        var product = this.state.product;
        product[event.target.name] = event.target.value;
        this.setState({
            product: product
        });
    }

    changeDropdown = (value, key) => {
        var dropdown_print = this.state.dropdown_print;
        if (value == null) {
            dropdown_print[key] = ""
        } else {
            dropdown_print[key] = this.state.list[key][value].name;
        }
        var product = this.state.product;
        product["product_" + key + "_id"] = value;
        this.setState({
            product: product,
            dropdown_print: dropdown_print
        });
    };

    toggleTax = e => {
        var new_price = this.state.new_price;
        new_price.is_taxable = e.currentTarget.checked
        this.setState({
            new_price: new_price
        })
    };

    changeAvailable = (available) => {
        this.getAvailablePrint(available);
        var product = this.state.product;
        product.available = available;
        this.setState({
            product: product
        });
    };

    getAvailablePrint(available) {
        var dropdown_print = this.state.dropdown_print;
        dropdown_print.available = available;
        if (available == null) {
            dropdown_print.available = "Not Available / Hidden";
        } else if (available == 0) {
            dropdown_print.available = "Sold Out";
        }
        this.setState({dropdown_print: dropdown_print});
    }

    saveChanges = event => {
        event.persist();
        event.preventDefault();
        var self = this;
        axios.defaults.withCredentials = true;
        if (this.state.product.product_factor_id == null) {
            Swal("Error", "Please select a factor", "error");
        } else if (this.state.product.product_category_id == null) {
            Swal("Error", "Please select a category", "error");
        } else {
            if (event.target.id == "add") {
                axios.post(API_ROOT + '/product/', {data: this.state.product})
                    .then(function (response) {
                        self.getProductInfo();
                        self.updatePricingHistory();
                        self.props.history.push("/product/" + self.props.match.params.product_parent_id + "/" + response.data.id);
                        toast.success("Product Added", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    });
            } else {
                axios.put(API_ROOT + '/product/' + this.props.match.params.product_id, {data: this.state.product, mode: event.target.id})
                    .then(function (response) {
                        console.log('OK');
                        toast.success("Changes Saved", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    });
            }
            localStorage.removeItem('products');
        }
    };

    // Add Rate

    openAdd = (product_pricing_category_id) => {
        this.setState({
            new_price: {
                product_pricing_category_id: product_pricing_category_id,
                category_name: this.state.list.pricing_categories[product_pricing_category_id].name,
                price: (typeof this.state.product.pricing[product_pricing_category_id].price == "undefined" ? 0 : this.state.product.pricing[product_pricing_category_id].price),
                deposit: (typeof this.state.product.pricing[product_pricing_category_id].deposit == "undefined" ? 0 : this.state.product.pricing[product_pricing_category_id].deposit),
                is_taxable: (typeof this.state.product.pricing[product_pricing_category_id].is_taxable == "undefined" ? 1 : this.state.product.pricing[product_pricing_category_id].is_taxable),
                is_sale: 0,
                start_date: moment(),
                label_date: moment().format('lll')
            },
            product_parent_id: null,
            mode: "Add"
        });
        this.toggleModal();
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    changeCategory = (product_pricing_category_id) => {
        var new_price = this.state.new_price;
        new_price.product_pricing_category_id = product_pricing_category_id;
        new_price.category_name = this.state.list.pricing_categories[product_pricing_category_id].name;
        this.setState({
            new_price: new_price
        });
    };

    changePrice = (event) => {
        var new_price = this.state.new_price;
        new_price[event.target.name] = event.target.value;
        this.setState({
            new_price: new_price
        });
    };

    changeQBO = (category_id, qb_id) => {
        var product = this.state.product;
        product.qb_ids[category_id] = qb_id;
        this.setState({
            product: product
        });
        axios.put(API_ROOT + '/qb_items/', {product_id: this.props.match.params.product_id, qb_id: qb_id, category_id: category_id, owner_id: this.state.owner_id})
            .then(function (response) {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    toggleSale = e => {
        var new_price = this.state.new_price;
        new_price.is_sale = 0;
        if (e.currentTarget.checked) {
            new_price.is_sale = 1;
        }
        this.setState({
            new_price: new_price
        })
    };

    updateDates(event, picker) {
        if (event.type == "apply") {
            var new_price = this.state.new_price;
            new_price.start_date = picker.startDate;
            new_price.label_date = picker.startDate.format('lll');
            this.setState({
                new_price: new_price,
            });
        }
    }

    savePrice = () => {
        if (this.state.new_price.price == null || this.state.new_price.price == "" || isNaN(this.state.new_price.price) || this.state.new_price.price < 0) {
            Swal("Error", "Please enter a valid price", "error");
        } else if (this.state.new_price.deposit == null || this.state.new_price.deposit == "" || isNaN(this.state.new_price.deposit) || this.state.new_price.deposit < 0) {
            Swal("Error", "Please enter a valid deposit", "error");
        } else {
            var self = this;
            var data = self.state.new_price;
            data.product_id = self.props.match.params.product_id;
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/product/' + this.props.match.params.product_id + '/price', self.state.new_price)
                .then(function (response) {
                    self.getProductInfo();
                    self.updatePricingHistory();
                    self.toggleModal();
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    Swal("Error", error.response.data.Message, "error");
                });
        }
    };
    deleteProduct = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "The product will be deleted. This will not affect sales history of this product.",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete now',
            cancelButtonText: 'No',
        }).then((result) => {
            axios.delete(API_ROOT + '/product/' + self.props.match.params.product_id)
                .then(function (response) {
                    self.getProductInfo();
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    self.props.history.push("/products/");
                })
                .catch(function (error) {
                    Swal("Error", error.response.data.Message, "error");
                });
        });
    };

    render() {

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <ol className="breadcrumb breadcrumb p-0">
                            <li className="breadcrumb-item">
                                <Link to="../../products">Products</Link>
                            </li>
                            <li className="breadcrumb-item">
                                {this.state.parent_name}
                            </li>
                        </ol>
                        <span>
                            {this.state.product.name}&nbsp;
                        </span>
                    </div>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Product Info</p>
                                <form className="form-horizontal" id={(isNaN(this.props.match.params.product_id) ? "add" : "info")} onSubmit={this.saveChanges}>
                                    <Row className={(isNaN(this.props.match.params.product_id) ? "d-none" : "mb-1")}>
                                        <label className="col-xl-3">Product #</label>
                                        <div className="col-xl-9">
                                            {this.props.match.params.product_id}
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Name</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="name" value={this.state.product.name} onChange={this.handleChange} required/>
                                            <div className="small text-muted pb-1">Description of product and size (e.g. Coors Light 6 Pack)</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Parent</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.parent}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.list.parent).map(function (parent_id, key) {
                                                        return (
                                                            <DropdownItem key={parent_id}
                                                                          onClick={() => this.changeDropdown(parent_id, "parent")}>{this.state.list.parent[parent_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Link to="../../lists/owner" className="ml-2 text-muted" title="Add/Edit List"><i class="fas fa-edit"></i></Link>
                                            <div className="small text-muted pb-1">Main product/liquid that customers first select</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Factor</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.factor}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.list.factor).map(function (factor_id, key) {
                                                        return (
                                                            <DropdownItem key={factor_id}
                                                                          onClick={() => this.changeDropdown(factor_id, "factor")}>{this.state.list.factor[factor_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Link to="../../lists/factor" className="ml-2 text-muted" title="Add/Edit List"><i class="fas fa-edit"></i></Link>
                                            <div className="small text-muted pb-1">Size or number of units of this product</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Channel</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.category}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.list.category).map(function (category_id, key) {
                                                        return (
                                                            <DropdownItem key={category_id}
                                                                          onClick={() => this.changeDropdown(category_id, "category")}>{this.state.list.category[category_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Link to="../../lists/category" className="ml-2 text-muted" title="Add/Edit List"><i class="fas fa-edit"></i></Link>
                                            <div className="small text-muted pb-1">Classification of this product</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">SKU</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="sku" value={this.state.product.sku} onChange={this.handleChange}/>
                                            <div className="small text-muted pb-1">(optional) Important for LCBO importing</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">TBS Article #</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="tbs_num" value={this.state.product.tbs_num} onChange={this.handleChange}/>
                                            <div className="small text-muted pb-1">(optional) Important for TBS importing</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Purchase Limit</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {this.state.dropdown_print.available}
                                                </DropdownToggle>
                                                <DropdownMenu style={{"zIndex": 4000}}>
                                                    <DropdownItem key={null} onClick={() => this.changeAvailable(null)}>Not Available / Hidden</DropdownItem>
                                                    <DropdownItem key={0} onClick={() => this.changeAvailable(0)}>Sold Out</DropdownItem>
                                                    {this.state.list.available.map(function (num) {
                                                        return (
                                                            <DropdownItem key={num} onClick={() => this.changeAvailable(num)}>{num}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <div className="small text-muted pb-1">Control if a product is in stock, and the max number per order</div>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Internal Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" name="notes" value={this.state.product.notes} onChange={this.handleChange}/>
                                        </div>
                                    </Row>
                                    <Row className="">
                                        <div className="offset-xl-3 col-xl-9">
                                            <button className="btn btn-primary" type="submit"
                                                    name="test">{(isNaN(this.props.match.params.product_id) ? "Add Product" : "Save Changes")}</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} className={(isNaN(this.props.match.params.product_id) ? "d-none" : "")}>
                        <Card className="card card-default">
                            <CardBody>
                                <p className="lead bb">Product Photo</p>
                                <div style={{textAlign: "center"}} className={(!this.state.display_photo ? "d-none" : "pb-2")}>
                                    <img src={"https://order.umbrel.thewebpeople.ca/img/products/" + this.props.match.params.product_id + ".jpg?" + this.state.photo_hash}
                                         style={{maxWidth: "100%", maxHeight: "250px"}} alt="Loading Photo..."/>
                                </div>
                                <div>
                                    <Upload product_id={this.state.product.product_id}/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className={(isNaN(this.props.match.params.product_id) ? "d-none" : "")}>
                    <Col lg={6} className={(isNaN(this.props.match.params.product_id) ? "d-none" : "")}>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Current Pricing</p>
                                <Row className="m-1 text-bold" style={{borderBottom: "1px solid #ececec"}}>
                                    <Col className="pl-0">Channel</Col>
                                    <Col className="pl-0">
                                        Price
                                    </Col>
                                    <Col className="pl-0">
                                        Deposit
                                    </Col>
                                    <Col className="pl-0">
                                        Taxable
                                    </Col>
                                    <Col></Col>
                                </Row>
                                {Object.keys(this.state.list.pricing_categories).map(function (category_id, key) {
                                    if (typeof this.state.product.pricing != "undefined") {
                                        return (
                                            <Row key={key} className="m-1" style={{borderBottom: "1px solid #ececec"}}>
                                                <Col className="pl-0">{this.state.list.pricing_categories[category_id].name}:</Col>
                                                <Col>
                                                    {(typeof this.state.product.pricing[category_id].price != "number" ? "Not Set" : "$" + this.state.product.pricing[category_id].price.toFixed(2))}
                                                    <Badge href="#" color="danger"
                                                           className={(this.state.product.pricing[category_id].is_sale == 1 ? 'ml-1' : 'd-none')}>Sale</Badge>
                                                </Col>
                                                <Col>
                                                    {(typeof this.state.product.pricing[category_id].deposit == "number" ? (this.state.product.pricing[category_id].deposit > 0 ? "$" + this.state.product.pricing[category_id].deposit.toFixed(2) : "$0") : "")}
                                                </Col>
                                                <Col>
                                                    <i className={(typeof this.state.product.pricing[category_id].is_taxable == "number" ? (this.state.product.pricing[category_id].is_taxable ? "fas fa-check" : "fas fa-times") : "d-none")}></i>
                                                </Col>
                                                <Col className="pr-0">
                                                    <Button size="xs" color="primary" className="mb-1"
                                                            onClick={() => this.openAdd(this.state.list.pricing_categories[category_id].product_pricing_category_id)}>Adjust</Button>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                }, this)}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Pricing History</p>
                                <Table bordered size="sm" className="text-right">
                                    <thead>
                                    <tr>
                                        <th className="text-left">Effective Date</th>
                                        {Object.keys(this.state.list.pricing_categories).map(function (category_id, key) {
                                            return (
                                                <th key={key}>{this.state.list.pricing_categories[category_id].name}</th>
                                            )
                                        }, this)}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.keys(this.state.pricing_history).map(function (date, key) {
                                        return (
                                            <tr key={key}>
                                                <td className="text-left">{moment(date).format("MMM D, YYYY")}</td>
                                                {Object.keys(this.state.list.pricing_categories).map(function (category_id, key) {
                                                    var category = this.state.list.pricing_categories[category_id].product_pricing_category_id;
                                                    return (
                                                        <td key={key}>
                                                            <div className={(typeof this.state.pricing_history[date][category].price != "number" ? "d-none" : "")}>
                                                                <Badge href="#" color="danger"
                                                                       className={(this.state.pricing_history[date][category].is_sale == 1 ? 'mr-1' : 'd-none')}>Sale</Badge>
                                                                ${this.state.pricing_history[date][category].price.toLocaleString("en-US")}
                                                                <div
                                                                    className="small text-muted">{(this.state.pricing_history[date][category].deposit > 0 ? "+$" + this.state.pricing_history[date][category].deposit.toLocaleString("en-US") : "No Deposit")}, {(this.state.pricing_history[date][category].is_taxable == 1 ? "+Tax" : "No Tax")}</div>
                                                            </div>
                                                        </td>
                                                    )
                                                }, this)}
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className={(isNaN(this.state.owner_id) || (this.state.owner_id != 1 && this.state.owner_id != 7) ? "d-none" : "")}>
                    <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                        <CardBody>
                            <p className="lead bb">Quickbooks Mapping</p>
                            <div className="alert alert-info">With great power comes great responsibility; please make changes carefully. Please don't select a QBO item that is a
                                bundle.
                            </div>
                            <Row className="m-1 text-bold" style={{borderBottom: "1px solid #ececec"}}>
                                <Col className="pl-0">Channel</Col>
                                <Col className="pl-0">
                                    QBO SKU
                                </Col>
                            </Row>
                            {Object.keys(this.state.account_types).map(function (category_id, key) {
                                if (typeof this.state.product.qb_ids[category_id] != "undefined") {
                                    var name = this.state.qbo_map[this.state.product.qb_ids[category_id]];
                                } else {
                                    var name = this.state.qbo_map[0];
                                }
                                return (
                                    <Row key={key} className="m-1" style={{borderBottom: "1px solid #ececec"}}>
                                        <Col className="pl-0">{this.state.account_types[category_id]}:</Col>
                                        <Col>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                                    {name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.qbo_map).map(function (qb_id, key) {
                                                        return (
                                                            <DropdownItem key={qb_id}
                                                                          onClick={() => this.changeQBO(category_id, qb_id)}>{this.state.qbo_map[qb_id]}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </Col>
                                    </Row>
                                )
                            }, this)}
                        </CardBody>
                    </Card>
                </div>
                <Button color="danger" onClick={this.deleteProduct}>Delete Product</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <div className="modal-header h4">Adjust Price</div>
                    <ModalBody className="pt-0">
                        <Form>
                            <FormGroup row>
                                <Label for="name" sm={3}>Category</Label>
                                <Col sm={9}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle color="secondary" caret size="sm" className="text-left">
                                            {this.state.new_price.category_name}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {Object.keys(this.state.list.pricing_categories).map(function (category_id, key) {
                                                return (
                                                    <DropdownItem key={key}
                                                                  onClick={() => this.changeCategory(category_id)}>{this.state.list.pricing_categories[category_id].name}</DropdownItem>
                                                )
                                            }, this)}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="price" sm={3}>Price</Label>
                                <Col sm={9}>
                                    <InputGroup className="mb-2">
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Input type="text" name="price" value={this.state.new_price.price} onChange={this.changePrice} required/>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <label className="col-xl-3 col-form-label">Taxable?</label>
                                <div className="col-xl-9">
                                    <FormGroup className="pt-1" check>
                                        <Label check>
                                            <Input type="checkbox" checked={this.state.new_price.is_taxable} onChange={this.toggleTax}/>{' '}
                                            Taxable (HST added on top of price)
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="price" sm={3}>Deposit</Label>
                                <Col sm={9}>
                                    <InputGroup className="mb-2">
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Input type="text" name="deposit" value={this.state.new_price.deposit} onChange={this.changePrice} required/>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="name" sm={3}>Effective Date</Label>
                                <Col sm={9}>
                                    <DateRangePicker
                                        startDate={this.state.new_price.start_date}
                                        minDate={moment()}
                                        onEvent={this.updateDates}
                                        autoUpdateInput={true}
                                        singleDatePicker={true}
                                    >
                                        <Input type="text" value={this.state.new_price.label_date} readOnly required/>
                                    </DateRangePicker>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="sale" sm={3}></Label>
                                <Col sm={9}>
                                    <FormGroup className="pt-1" check>
                                        <Label check>
                                            <Input type="checkbox" checked={this.state.new_price.is_sale} onChange={this.toggleSale}/>{' '}
                                            Mark as a <Badge href="#" color="danger">Sale</Badge> price
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.savePrice}>Save</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}


const Upload = (props) => {
    const getUploadParams = ({file, meta}) => {
        const url = API_ROOT + '/photos/' + props.product_id;
        const body = new FormData();
        body.append('image', file);
        return {url, body}
    };

    const handleChangeStatus = ({meta, remove}, status) => {
        if (status === 'headers_received') {
            window.ProductComponent.checkPhoto();
            remove()
        } else if (status === 'aborted') {
            Swal("Error", "Upload Failed", "error");
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept="image/*"
                withCredentials={true}
                inputContent={(files, extra) => (extra.reject ? 'JPG or PNG files only' : "Upload a Product Image (drag n' drop or click)")}
                styles={{
                    inputLabel: {fontSize: "14px", fontWeight: "normal"},
                    dropzone: {height: 100, minHeight: 75, border: "1px dashed #ccc",},
                    dropzoneActive: {borderColor: 'green'},
                }}
            />
        </React.Fragment>
    )
};

export default (ProductOverview);

